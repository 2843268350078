import React from 'react'
import { IntlProvider } from 'react-intl'

import Layout from './layout'

import messages from '../../locale/de'
// import en from 'react-intl/locale-data/de'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/de'
import 'intl/locale-data/jsonp/de'
import 'intl-pluralrules'

// addLocaleData(en)

const LayoutEN = ({ children }) => (
  <IntlProvider locale="de" messages={messages}>
    <Layout>{children}</Layout>
  </IntlProvider>
)

export default LayoutEN
