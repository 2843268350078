const locale = {
  LOCALE: 'de',

  LANG_PL: 'PL',
  LANG_EN: 'ENG',
  LANG_DE: 'DE',

  NAVBAR_MENU_SPECIALIZATION: 'Rechtsgebiete',
  NAVBAR_MENU_TEAM: 'Anwälte',
  NAVBAR_MENU_RESPNSIBILITY: 'CSR',
  NAVBAR_MENU_CONTACTS: 'Kontakt',
  NAVBAR_MENU_PART_OF: 'Mietglied von',

  BUTTON_MORE: 'MEHR',
  BUTTON_HIDE: 'Verstecken',

  BUTTON_CHECK: 'PRÜFEN',

  BUTTON_MAP_PART_ONE: 'FINDEN SIE UNS',
  BUTTON_MAP_PART_TWO: 'AUF GOOGLE MAPS',
}

export default locale
